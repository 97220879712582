import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Facilitation`}</h1>
    <p>{`If you are new to facilitation, Swerhun has published a wonderful book on their methods. `}<a parentName="p" {...{
        "href": "https://www.swerhun.com/readbookonline"
      }}>{`https://www.swerhun.com/readbookonline`}</a></p>
    <p>{`Maintained by `}<a parentName="p" {...{
        "href": "https://twitter.com/lizbarry"
      }}>{`Liz Barry`}</a></p>
    <p>{`While a `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` does not itself deliver a readymade decision for a course of action or answer to a philosophical dilemma, the results of a `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` may `}<strong parentName="p">{`provide input to a range of group processes such as governance or collective sense-making`}</strong>{`. Whether the group process is formal or informal, illustrative or binding, with defined participation or anonymous, among many other variations, there is usually a facilitator who suggested or designed the process, and who can guide the incorporation of `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` results.`}</p>
    <p>{`Facilitators should consider if the massive input of polis is best suited during the formative phase or the feedback phase of a given process, or perhaps whether the journey is the destination. In vTaiwan—which is structured along the lines of the ICA-developed global best practice for facilitation `}<strong parentName="p">{`ORID`}</strong>{`—polis is used in the "reflection" phase, and participants continue in the same `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` to the "ideational" phase.`}</p>
    <h3>{`Examples by domain:`}</h3>
    <ul>
      <li parentName="ul">{`Use of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` by a news organization`}
        <ul parentName="li">
          <li parentName="ul">{`Polis can replace the comment section on articles to collate public response. Polis can also be used early in a journalistic endeavor for discovery. Journalists themselves may act as the facilitator.`}</li>
          <li parentName="ul">{`See case studies:`}
            <ul parentName="li">
              <li parentName="ul">{`"`}<strong parentName="li">{`Bowling Green Daily News`}</strong>{` Bowling Green, Kentucky, USA"`}</li>
              <li parentName="ul">{`"`}<strong parentName="li">{`WFPL`}</strong>{`, Louiseville, Kentucky, USA"`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Use of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` by a government`}
        <ul parentName="li">
          <li parentName="ul">{`Polis can engage hundreds of thousands of participants, vastly more than could be convened through analog public consultations. Civil servants may act as the facilitator.`}</li>
          <li parentName="ul">{`See case studies:`}
            <ul parentName="li">
              <li parentName="ul">{`"`}<strong parentName="li">{`vTaiwan`}</strong>{`, Taipei, Taiwan"`}</li>
              <li parentName="ul">{`"`}<strong parentName="li">{`Ministry of Canadian Heritage`}</strong>{`, Toronto, Canada"`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Use of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` with representative sampling`}
        <ul parentName="li">
          <li parentName="ul">{`Polling companies can assemble a representative sample to participate in a polis `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` so that results may be extrapolated to a larger population. Elected representatives can act as a facilitator, enabling their constituency to more clearly signal their perspectives and situations through the use of polis.`}</li>
          <li parentName="ul">{`See case study:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Demos`}</strong>{`, UK `}<a parentName="li" {...{
                  "href": "https://demos.co.uk/project/polis-and-the-political-process/"
                }}>{`https://demos.co.uk/project/polis-and-the-political-process/`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Use of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` in a Citizens Assembly`}
        <ul parentName="li">
          <li parentName="ul">{`Using polis to listen to the broader population can be useful in the `}<strong parentName="li">{`input phase`}</strong>{` of a Citizens Assembly, which comes `}<strong parentName="li">{`after`}</strong>{` the selection of assembly members by civic lottery and the input phase of learning from rightsholders, stakeholders and experts, and `}<strong parentName="li">{`before`}</strong>{` facilitated deliberation in small groups and drafting recommendations.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      